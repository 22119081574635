/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DevicesIcon from '@material-ui/icons/Devices';
import Typography from '@material-ui/core/Typography';

import NewRentModal from './modals/NewRentModal';
import { fetchAvailableRentals } from '../utils/Cachengo';
import { addEventHandler, removeEventHandler } from '../actions/items';
import CustomTable from './CustomTable';


const mapStateToProps = (state) => {
    return {
      payInfo: state.payInfo || {},
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  }
}

function ConnectedRentNodeTable (props) {
  const tableRef = useRef();
  const [isNewRentModalOpen, setNewRentmodal] = useState(false)
  const [deviceHandles, setDeviceHandles] = useState([]);  

  const buyer = props.payInfo.buyer || {};
  const canBuy = buyer.can_buy || false; 

  useEffect(() => {
    props.addEventHandler('rentNodeTable', (message) => {
      if (message.event === 'device_rented' || message.event === 'device_set_rent' || message.event === 'device_set_unrent') {
        refreshData()
      }
    })
    return () => props.removeEventHandler('rentNodeTable')
  }, [tableRef])

  const clearSelectedItems = () => {
    tableRef.current && tableRef.current.clearSelectedItems()
  }
  
  const toggleRentModal = (rowData) => {
    if ( isNewRentModalOpen ){
      setNewRentmodal( !isNewRentModalOpen )
    } else {
      setNewRentmodal(!isNewRentModalOpen);
      let handles = rowData.map(device => {
        return {handle: device.handle, name: device.name}
      });
      setDeviceHandles(handles)
    }
  }

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  const deviceIcon = props => {
    return (
      <DevicesIcon {...props} style={{ margin: '-4px 8px 0 0' }} color='secondary' />
    )
  };
  const columnHead = {
    field: 'name', icon: deviceIcon
  }
  const columns = [
    {
      render: rowData => {
        return (
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Price: ${rowData.price}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Cores: {rowData.cores}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                ARCH: {rowData.arch}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                RAM: {parseFloat((rowData.ram / Math.pow(1024, 3)).toFixed(1))} GB
            </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Location: {rowData.city}, {rowData.province}. {rowData.country}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Accelerator: {rowData.accelerator}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  const shoppingCartIcon = (props) => (
    <ShoppingCartIcon {...props} />
  );

  const actions = [
    {
      icon: shoppingCartIcon,
      tooltip: 'Rent-a-Node',
      onClick: (event, rowData) => {
        toggleRentModal(rowData)
      },
    },
  ]

  return (
    <div>
    {canBuy ? 
    <Grid style={{paddingTop: '20px'}}>
        <CustomTable
            ref={tableRef}
            multiSelect={true}
            title='Rent-a-Node'
            getData={fetchAvailableRentals}
            actions={actions}
            columns={columns}
            columnHead={columnHead}
            errMessage={'Problem fetching rental devices'}
        />
        <NewRentModal 
            show={isNewRentModalOpen}
            handleClose={toggleRentModal}
            handles={deviceHandles}
            clearSelectedItems={clearSelectedItems}
            refreshTable={refreshData}
        />
        </Grid>
    :
    <Grid style={{paddingTop: '20px'}}>
        <Typography>
            To activate node rental capabilities, 
            please enter your payment information in the "Balances" section.
        </Typography>
    </Grid> 
    }   
    </div>
  );
}

const RentNodeTable = connect(
  mapStateToProps, mapDispatchToProps
)(ConnectedRentNodeTable);

export default RentNodeTable;
/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React from 'react';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { createAccount } from '../utils/Cachengo';
import { displayRequestError, setSnackBarMessage } from '../actions/interactions';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#404846',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ConnectedSignUp = props => {
  const classes = useStyles();
  let history = useHistory();
  const [formValues, setFormValues] = React.useState({
    username: "",
    email: '',
    password: ""
  });

  const handleInputChange = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    createAccount(
      formValues.username, 
      formValues.email, 
      formValues.password, 
      props.displayRequestError
    ).then(res => { 
      if(res && res.success){
        history.push("/");
        props.setSnackBarMessage(
          'Account Successfully created, please check your email to confirm your account', 'success')
      }
     });
    
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create a Cachengo Account
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            value={formValues.username}
            onChange={handleInputChange('username')}
            color='secondary'
          />
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type='email'
            label="Email Address"
            name="email"
            value={formValues.email}
            onChange={handleInputChange('email')}
            color='secondary'
          />
          <TextField
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formValues.password}
            onChange={handleInputChange('password')}
            color='secondary'
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <MLink
                component={Link}
                to="/"
                variant="body2"
              >
                {"Already have an account? Sign in"}
              </MLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    displayRequestError: (res) => dispatch(displayRequestError(res)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const SignUp = connect(null, mapDispatchToProps)(ConnectedSignUp);

export default SignUp;

import React from 'react'

export default function SledIcon(props) {
  const {
    device_in_sled,
    sled_position,

  } = props;

  return (
    <div style={{marginRight: '10px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="89" viewBox="0 0 51 89">
        <g id="Rectangle_707" data-name="Rectangle 707" fill="#ebe7e5" stroke={device_in_sled ? "#f4521d" : "#959595"} strokeWidth="1.5">
          <rect width="51" height="89" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="50" height="88" rx="1.5" fill="none" />
        </g>
        <circle id="Ellipse_23" data-name="Ellipse 23" cx="6.5" cy="6.5" r="6.5" transform="translate(8 8)" fill={device_in_sled && sled_position === 4 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_24" data-name="Ellipse 24" cx="6.5" cy="6.5" r="6.5" transform="translate(8 28)" fill={device_in_sled && sled_position === 3 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_25" data-name="Ellipse 25" cx="6.5" cy="6.5" r="6.5" transform="translate(8 48)" fill={device_in_sled && sled_position === 2 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_26" data-name="Ellipse 26" cx="6.5" cy="6.5" r="6.5" transform="translate(8 68)" fill={device_in_sled && sled_position === 1 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_27" data-name="Ellipse 27" cx="6.5" cy="6.5" r="6.5" transform="translate(31 8)" fill={device_in_sled && sled_position === 8 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_28" data-name="Ellipse 28" cx="6.5" cy="6.5" r="6.5" transform="translate(31 28)" fill={device_in_sled && sled_position === 7 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_29" data-name="Ellipse 29" cx="6.5" cy="6.5" r="6.5" transform="translate(31 48)" fill={device_in_sled && sled_position === 6 ? "#f4521d" : "#fff"} />
        <circle id="Ellipse_30" data-name="Ellipse 30" cx="6.5" cy="6.5" r="6.5" transform="translate(31 68)" fill={device_in_sled && sled_position === 5 ? "#f4521d" : "#fff"} />
      </svg>

    </div>
  )
}

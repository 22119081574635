/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';

import { runOnEnter } from '../../utils/Helpers';


const SledEditModal = props => {

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({})
  }, [props.show])

  const handleInputChange = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.value
    })
  }

  const saveValues = () => {
    const data = {
      sled_handle: props.rowData.sled_handle,
      position: formValues.sled_number ? parseInt(formValues.sled_number) : props.rowData.sled_number,
      product_handle: formValues.product_handle ? formValues.product_handle : props.rowData.product_handle
    }
    props.handleSubmit(data)
  }

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
      fullWidth
    >
      <DialogTitle>
        Edit Sled
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Sled ID"
          defaultValue={props.rowData.sled_handle}
          margin="dense"
          type="text"
          fullWidth
          color='secondary'
          disabled
        />
        <TextField
          label="Product handle"
          defaultValue={props.rowData.product_handle}
          onChange={handleInputChange('product_handle')}
          margin="dense"
          type="text"
          fullWidth
          color='secondary'
          onKeyPress={runOnEnter(saveValues)}
        />
        <TextField
          // TODO: VALIDATION
          label="Sled Position"
          defaultValue={props.rowData.sled_number}
          onChange={handleInputChange('sled_number')}
          margin="dense"
          type="number"
          fullWidth
          color='secondary'
          onKeyPress={runOnEnter(saveValues)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveValues}>Save</Button>
        <Button onClick={props.handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )

}


export default SledEditModal;

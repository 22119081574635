/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ExtensionIcon from '@material-ui/icons/Extension';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import VariableTypeInput from './VariableTypeInput';
import MagicCopy from "./MagicCopy";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  innerDiv: {
    padding: theme.spacing(0, 4, 2),
  }

}));

export default (props) => {
  const classes = useStyles();

  const { 
    item,
    InstallName,
    setInstallName,
    parameters,
    setParameters,
    removeItemFromInstallation,
    magicFillOptions,
    copyMagicfill
  } = props;

  const handleInputChange = paramIndex => event => {
    const value = event.target.value;
    var params = parameters;
    params[paramIndex].value = value;
    setParameters([...params]);
  }

  const values = (paramIndex) => { 
    if (!parameters[paramIndex]){
      return ''
    }
    if(parameters[paramIndex].value){
      return parameters[paramIndex].value;
    }
    return parameters[paramIndex].parameter_type === 'array' ? [] : '';
  }

  const isEmpty = (value) => {
    if (!value) {
      return true
    }
    if (value.length < 1) {
      return true
    }
    return false
  }

  if(!item.type){
    return <Header item={item} />
  }

  return (
    <div >
      <Header 
        item={item} 
        removeItemFromInstallation={removeItemFromInstallation} 
      />
      <div className={classes.innerDiv}>
        <Typography style={{ paddingTop: '8px' }} variant='body1'>Installation Name</Typography>
        <div style={{ paddingLeft: '8px'}}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label='Installation Name'
            type="text"
            value={InstallName}
            onChange={(e)=>setInstallName(e.target.value)}
            style={{ maxWidth: '350px',}}
            color='secondary'
          />
        </div>
        <Typography style={{ paddingTop: '24px'}} variant='body1'>Parameters</Typography>
        <Grid container spacing={2} >
          {parameters.map((param, paramIndex) => (
            <Grid item xs={6} key={paramIndex} style={{ padding: '8px 16px 16px' }}>
              <Typography variant='body2'>{param.display_name}</Typography>
              <Typography color='textSecondary' variant='caption'>{param.description}</Typography>
              <div style={{ display: 'flex', alignItems:'flex-end' }}>
                <div style={{ maxWidth: '350px' }}>
                  <VariableTypeInput
                    margin="dense"
                    label="Value"
                    fullWidth
                    color='secondary'
                    placeholder={param.parameter_type}
                    type={param.parameter_type}
                    onChange={handleInputChange(paramIndex)}
                    value={values(paramIndex)}
                  />
                </div>
                
                {!(param.parameter_type === 'number') && isEmpty(param.value) &&
                  <MagicCopy
                    paramIndex={paramIndex}
                    magicFillOptions={magicFillOptions}
                    copyMagicfill={copyMagicfill}
                  />
                }
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )

}

const Header = ({ item, removeItemFromInstallation }) => {
  const classes = useStyles();

  let msg = "Please click on Applications to select an App for installation"
  return(
    <div>
      <Typography style={{ padding:'16px 8px 0' }} variant="h6" gutterBottom>
        App to be installed
      </Typography>
      <Grid container spacing={1}  className={classes.root}>
        <Grid container item lg={6} justify='space-between'>
          <Grid item className={classes.header}>
            <ListItemAvatar>
              <Avatar style={item.type ? { backgroundColor: '#FC5001' } : {}}>
                <ExtensionIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={item.type ? item.description : msg}
            />
          </Grid>
          {item.type && 
            <Grid item >
              <IconButton onClick={removeItemFromInstallation} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Grid>
            
          }
        </Grid>
      </Grid>
    </div>
  )
}

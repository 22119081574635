/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ProductEditModal from './modals/ProductEditModal';
import Tooltip from "@material-ui/core/Tooltip";

import Items from './Items';
import { makeStyles } from '@material-ui/core/styles';
import { getDevicesByProduct, editProduct } from '../utils/Cachengo'
import {connect} from 'react-redux';
import { removeEventHandler, addEventHandler } from '../actions/items';

const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(2, 0),
    },
    itemBox: {
      padding: theme.spacing(1),
      border: 'solid 1px',
      borderColor: theme.palette.divider,
      borderRadius: '5px',
      cursor: 'pointer',
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      width: "100%",
      height: "100%",
      transition: theme.transitions.create("box-shadow", {
        easing: theme.transitions.easing.sharp,
        duration: ".3s"
      }),
      "&:hover": {
        boxShadow: theme.shadows[1],
      }
    },
    active: {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.background.default,
    },
    itemBoxHeader: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),
    },
    dataInfoDiv: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
    },
  
    body: {
      padding: theme.spacing(4),
      display: 'none',
      overflow: 'hidden',
      maxHeight: '0px',
      transition: 'max-height .9s ease-out'
    },
    expIcon: {
      transition: 'transform .2s ease-out'
    },
    showing: {
      transform: 'rotate(180deg)',
    },
    Productroot: {
      padding: theme.spacing(0,0,2)
    },
  }));

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  };
}

const ConnectedProduct = function(props) {
    const classes = useStyles();
    const [show, setShow] = useState([false])
    const [checked, setChecked] = useState([])
    const [devices, setDevices] = useState({})
    const [showEditModal, setShowEditModal] = useState(false);
    const [productEdit, setProductEdit] = useState({});
    
  
    useEffect(() => {
      props.addEventHandler('productDevices', (message) => {
        if (message.event == 'new_device' || message.event == 'remove_device' || message.event === 'edit_device' || message.event === 'device_online' || message.event === 'device_offline') {
        {
            refreshDevices(message);   
        }  
      }
      })
    });

    const refreshDevices = (message) =>{
      getDevicesByProduct(message.product).then(res =>{
        if (res && res.success) {
          let devicesCopy = {...devices};
          if (props.data[i].name.toLowerCase() === 'rentals') { 
            devicesCopy["rentals"] = res.devices.filter(device => device.is_vm === true); 
          } else if (props.data[i].name === 'Single Devices') {  
            devicesCopy[handle] = res.devices.filter(device => device.is_vm === false);
          } else 
            devicesCopy[message.product] = res.devices;
            setDevices(devicesCopy);
        }
      });
    }

    const toggleShow = (i) => {
      let state = [...show]
      state[i] = !show[i]
      setShow(state)
      if (state[i]) {
        const handle = props.data[i].handle;
        getDevicesByProduct(handle).then(res => {
          let devicesCopy = {...devices};
          if (props.data[i].name.toLowerCase() === 'rentals') { 
            devicesCopy["rentals"] = res.devices.filter(device => device.is_vm === true); 
          } else if (props.data[i].name === 'Single Devices') {  
            devicesCopy[handle] = res.devices.filter(device => device.is_vm === false);
          } else 
            devicesCopy[handle] = res.devices;
            setDevices(devicesCopy);
        })
      }
    }  

    const toggleEdit = (i) => {
      if (showEditModal){
        setProductEdit({})
        setShowEditModal(false)
        return;
      }

      setProductEdit(props.data[i])
      setShowEditModal(true)
    }

    const handleSubmit = (name) => {
      const data = {
        handle: productEdit.handle,
        product_name: name
      }
      editProduct(data).then(res => {
        if(res && res.success){
          props.handleRefreshData()
          setShowEditModal(false)
        }
      })
    }

    const handleChangeSelectAll = (e, i, handle) => {
  
      let state = [...checked]
      state[i] = e.target.checked
      setChecked(state)
  
      let isChecked = state[i]
      let product_devices = devices[handle] || [];
      props.handleSelectAll(isChecked, product_devices)
    }
  
    const isAllSelected = (devices=[]) => {
      if(props.selectedItems.length <  1 || devices.length < 1){
        return false
      }
      let devs = devices.map(device => device.handle)
      let selected = props.selectedItems.map(device => device.handle)
  
      return devs.every(d => selected.includes(d));
    }
    if (props.data === undefined) {
        return <div></div>
    }

    return (
        <div >
          {props.data.map((product, i) => (
            <div className={classes.Productroot} key={i}>
              <div className={classes.head} style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  size='small'
                  onClick={() => toggleShow(i)}
                  aria-label={`expand-devices-todo-name`}
                  style={{marginLeft: '-8px'}}
                >
                  <ExpandMoreIcon className={clsx(classes.expIcon, { [classes.showing]: show[i] })} />
                </IconButton>
    
                <Typography variant='body1' style={{ paddingRight: '8px', fontWeight: 'bold' }}>{product.name}</Typography>
                <Typography variant='body2' color='secondary'>{product.product_type}</Typography>
                <div style={{flex: 1}}/>
                { product.handle &&
                  <Tooltip title={'Edit product name'} placement="top" >
                    <IconButton
                      onClick={() => toggleEdit(i)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                }
                <FormControlLabel
                  style={{ marginRight: '0' }}
                  control={
                    <Checkbox
                      checked={isAllSelected(devices[product.handle])}
                      onChange={(e) => handleChangeSelectAll(e, i, product.handle)}
                      name="checkedA"
                      color="secondary"
                    />
                  }
                  label="Select all"
                />
              </div>
              <Collapse in={show[i]}>
                <div style={{padding: '0 10px'}}>
                  {devices[product.handle] ? <Items {...props} data={devices[product.handle]}/> : <p>Loading...</p>}
                </div>
              </Collapse>
            </div>
          ))}
        <ProductEditModal
          product={productEdit}
          show={showEditModal}
          handleClose={toggleEdit}
          handleSubmit={handleSubmit}
        />
        </div>
    )
}

const Product = connect(null, mapDispatchToProps)(ConnectedProduct);
export default Product;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { getReadme, getUploadToken, uploadFile } from '../utils/Cachengo';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { setSnackBarMessage } from '../actions/interactions';

const useStyles = makeStyles(theme => ({
  root: {},
  hide: {
    display: 'none'
  }

}))

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

function ConnectedReadmeViewer(props) {
  const [content, setContent] = useState('');
  const [cancelReadme, setCancelReadme] = useState('');
  const [isEditing, setEditing] = useState(false)
  const { handle } = useParams();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getReadme(handle).then(res => {
      if(res.status == 200){
        const reader = res.body.getReader();
        if (reader) {
          const stream = new ReadableStream({
            start(controller) {
              function push() {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                setContent(content + String.fromCharCode.apply(String, value))
                push();
              });
            };
            push();
            }
          })
        }
      }
    });
  }, [handle])

  const goBack = () => {
    history.goBack()
  }
  
  const handleSave = () => {
    let app_handle = handle
    getUploadToken(app_handle, 'md').then(minioResp => {
        let bucketUrl = minioResp.token.url
        let mininoFields = minioResp.token.fields
        return uploadFile(bucketUrl, content, mininoFields)
    }).then(
      setEditing(false)
    ).catch((e) => {
      console.log(e)
      props.setSnackBarMessage('There was a problem editing readme file', 'error')
    })
  }

  const handleCancel = () => {
    setContent(cancelReadme);
    setEditing(false);
  }

  const handleEdit = () => {
    setCancelReadme(content)
    setEditing(true);
  }

  const codeHighlighter = ({value, language}) => {
    return(
      <SyntaxHighlighter language={language ?? null} style={docco}>
        {value ?? ''}
      </SyntaxHighlighter>
    );
  }
  
  return (
  <Grid container spacing={2} style={{marginTop: '10px'}}>
    <Grid item sm={12}>
      <div style={{ paddingLeft: '45px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
        <Typography variant='h5'>{props.location.name}</Typography>
        <div>
          {
          <Tooltip title={'Edit'} placement="top">
            <IconButton
              onClick={handleEdit}
              aria-label="edit-btn"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          }
          <IconButton
            onClick={goBack}
            aria-label="close-btn"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Grid>
    <Grid item xs={12}>
      <div className={clsx({ [classes.hide]: !isEditing })}>
        <Button 
          style={{ float: 'right'}}
          variant='outlined'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button 
          style={{ float: 'right', marginRight: '20px' }}
          color='secondary'
          variant='contained'
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Grid>
    <Grid item xs={12} className={clsx({ [classes.hide]: isEditing })}>
      <div style={{height: '100%', minWidth: '100%', overflow: 'auto', overflowY: 'auto', paddingLeft: '45px'}}>
        <ReactMarkdown 
          source={content}
          className="markdown"
          renderers={{
            code: codeHighlighter,
          }}
        />
      </div>
    </Grid>
    <Grid item xs={12} className={clsx({ [classes.hide]: !isEditing })}>
      <div style={{height: '100%', minWidth: '100%', overflow: 'auto', overflowY: 'auto', paddingLeft: '45px'}}>
        <TextareaAutosize
          rowsMin={20}
          label="EditReadme"
          type="text"
          style={{ height: '600px', width: '100%', border: 'none'}}
          value={content}
          onChange={(e)=>setContent(e.target.value)}
        />
      </div>
    </Grid>
  </Grid>
  );
}

const ReadmeViewer = connect(null, mapDispatchToProps)(ConnectedReadmeViewer);

export default ReadmeViewer;

import React, {useEffect, useState} from 'react';
import {getAppLogo} from '../utils/requests';
import Avatar from '@material-ui/core/Avatar';

export default function AppIcon(props)
{
    const [imgData, setImgData] = useState(null);

    useEffect(() =>{
        if (imgData === null)
        {
            getAppLogo(props.appHandle)
            .then(data => {
                setImgData(data);
            });
        }
    },[props.appHandle]);

    return (
        <Avatar
          alt={props.name}
          src={imgData}
          style={props.style}>
        </Avatar>
    )
}
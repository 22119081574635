import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
  menu: {
    minWidth: '280px',
    padding: theme.spacing(.5,1)
  },
  divider: {
    margin: theme.spacing(1.5, 1, 1.5, 2),
  },
  textProfile: {
    paddingLeft: theme.spacing(2)
  },
}))

export default props => {
  const classes = useStyles();
  const {
    anchorEl,
    open,
    handleClose,
    openLogout,
    handleChangeOrg
  } = props;

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      PopoverClasses={{ paper: classes.menu }}
    >
      <div />
      <MenuList>
        <MenuItem 
          component={Link} 
          to={`/profile`} 
          onClick={handleClose} 
        >
          <ListItemIcon>
            <PersonIcon  />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem
          onClick={handleChangeOrg}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          Change Organization
        </MenuItem>
        <Divider variant='middle' className={classes.divider} />
        <MenuItem
          onClick={openLogout}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  )
};

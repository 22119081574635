/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
 import React, { useEffect, useState, useRef } from "react";
 import moment from "moment";
 import Grid from "@material-ui/core/Grid";
 
 import { connect } from "react-redux";
 import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
 import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
 import DeleteIcon from "@material-ui/icons/Delete";
 import GetAppIcon from "@material-ui/icons/GetApp";
 import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
 import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

 
 
 import SetRentPriceModal from "./modals/SetRentPriceModal";
 import SetUninstallAppsModal from "./modals/SetUninstallAppsModal";
 import { removeDevice, getProducts, updateDevice } from "../utils/Cachengo";
 import {
   setSnackBarMessage,
   setConfirmationModalOpen,
 } from "../actions/interactions";
 import {
   setDevicesToInstall,
   removeMultipleDevices,
 } from "../actions/installation";
 import { removeEventHandler, addEventHandler } from "../actions/items";
 
 import InstallerTable from "./InstallerTable";
 import Product from "./Product";
 
 import { getInstallations } from "../utils/Cachengo";
 import MoveDevicesModal from "./modals/MoveDeviceModal";

 
 function mapDispatchToProps(dispatch) {
   return {
     removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
     setItemToInstall: (device) => dispatch(setDevicesToInstall(device)),
     removeEventHandler: (key) => dispatch(removeEventHandler(key)),
     addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
     openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
     setSnackBarMessage: (message, type) =>
       dispatch(setSnackBarMessage(message, type)),
   };
 }
 
 const ConnectDeviceTable = (props) => {
   const tableRef = useRef();
   const [isPriceModalOpen, setPriceModal] = useState(false);
   const [isUninstallModalOpen, setUninstallModal] = useState(false);
   const [deviceHandles, setHandles] = useState([]);
   const [deviceInstallations, setInstallations] = useState({});
   const [isMoveDevicesModalOpen, setMoveDevicesModal] = React.useState(false);

   useEffect(() => {
     props.addEventHandler("deviceTable", (message) => {
       if (
         message.event === "new_device" ||
         message.event === "remove_device" ||
         message.event === "edit_device"
       ) {
         refreshData();
       }
     });
     return () => props.removeEventHandler("deviceTable");
   }, [tableRef]);
 
   const refreshData = () => {
     tableRef.current && tableRef.current.refreshTable();
   };
 
   const clearSelectedItems = (handles) => {
     props.removeMultipleDevices(handles);
   };
 
   const handleDeleteDevice = (rowData) => {
     let handles = rowData.map((device) => device.handle);
     return removeDevice(handles).then(() => {
       props.removeMultipleDevices(handles);
     });
   };
 
   const handleUpdateDevice = (rowData) => {
     let handles = rowData.map((device) => device.handle);
     return updateDevice(handles).then(() => {
       props.removeMultipleDevices(handles);
     });
   };
 
   const toggleSetRentPriceModal = (rowData) => {
     if (isPriceModalOpen) {
       setPriceModal(!isPriceModalOpen);
     } else {
       let devices = rowData.filter((device) => !device.is_vm);
       let handles = devices.map((device) => device.handle);
 
       let numberNotEntered = rowData.length - devices.length;
       if (numberNotEntered === rowData.length) {
         let errMsg = "All devices are VMs and cannot be set for rent";
         props.setSnackBarMessage(errMsg, "error");
         return;
       }
 
       if (numberNotEntered > 0) {
         let msg1 = `${numberNotEntered} device was not set for rent because it is a VM`;
         let msg2 = `${numberNotEntered} devices were not set for rent because they are VMs`;
         let message = numberNotEntered > 1 ? msg2 : msg1;
         props.setSnackBarMessage(message, "warning");
       }
       setPriceModal(!isPriceModalOpen);
       setHandles(handles);
     }
   };
 
   function parseInstallations(handles, installations) {
     let appsToUninstall = [];
 
     if (handles.length > 1) {
       let appsDict = {};
 
       for (let i = 0; i < handles.length; i++) {
         for (
           let n = 0;
           n < installations[handles[i]].length;
           n++
         ) {
           if (!appsDict[installations[handles[i]][n].name]) {
             appsDict[installations[handles[i]][n].name] = 1;
           } else {
             appsDict[installations[handles[i]][n].name]++;
           }
         }
       }
      
       if (Object.keys(appsDict)) {
         for (let key in appsDict) {
           if (appsDict.hasOwnProperty(key)) {
             if (appsDict[key] != handles.length) {
              delete appsDict[key];
               }
           }
         }
         for (let n = 0; n < Object.keys(installations).length; n++) {
          installations[Object.keys(installations)[n]] = installations[Object.keys(installations)[n]].filter((app) => Object.keys(appsDict).includes(app.name))
         }
         return installations;
       }
       return {};
     }
   }
 
   const toggleUninstallAppsModal = (rowData) => {
     if (isUninstallModalOpen) {
       setUninstallModal(!isUninstallModalOpen);
     } else {
       let devices = rowData;
       let handles = devices.map((device) => device.handle);
       let installations = {};
 
       (async () => {
         for (let i = 0; i < handles.length; i++) {
           const res = await getInstallations(handles[i]);
           if (res) {
             installations[handles[i]] = res.installations;
           }
         }
       })().then(() => {
         setHandles(handles);
         for (let i = 0; i < Object.keys(installations).length; i++) {
           installations[Object.keys(installations)[i]] = installations[
             Object.keys(installations)[i]
           ].filter((app) => app.state == "Installed");
         }
         if (handles.length > 1) {
           installations = parseInstallations(handles, installations);
         }
         setInstallations(installations);
         setUninstallModal(!isUninstallModalOpen);
       });
     }
   };
 
   const deviceIcon = (rowData) => {
     let time = moment().diff(moment.unix(rowData.last_seen), "minutes");
     let color = rowData.is_connected ? "#4caf50" : "#e53935";
     if (time > 6) {
       color = "#e53935";
     }
     if (rowData.is_connected == null && time < 6) {
       color = "#4caf50";
     }
     return <FiberManualRecordIcon style={{ margin: "-3px 4px 0 0", color }} />;
   };
 
   const columns = [
     { title: "Local IP", field: "private_ip" },
     { title: "Local IPv6", field: "private_ipv6" },
     { title: "MAC", field: "mac" },
     { title: "Accelerator", field: "accelerator"},
     { title: "Virtual Machine", field: "is_vm", type: "boolean" },
     { title: "Status", field: "status" },
   ];
   const columnHead = {
     field: "name",
     renderIcon: deviceIcon,
   };
 
   const handleInfo = (e, rowData) => {
     const handle = rowData.handle;
     let idUrl = "/deviceinfo/" + handle;
     props.history.push({
       pathname: idUrl,
       isInternal: true,
     });
   };
 
   const moreInfo = {
     tooltip: "Device Info",
     handleMoreInfo: handleInfo,
   };

   const toggleMoveDevicesModal = (rowData) => {
    console.log(isMoveDevicesModalOpen)
    let devices = rowData;
    if (isMoveDevicesModalOpen == false) {
      let handles = devices.map((device) => device.handle);
      setHandles(handles);
      setMoveDevicesModal(true);
    } else {
      setMoveDevicesModal(false);
    }
  };

 
   const removeItemIcon = (props) => <DeleteIcon {...props} />;
 
   const addShoppingCart = (props) => <AddShoppingCartIcon {...props} />;
 
   const updateDeviceIcon = (props) => <GetAppIcon {...props} />;
 
   const uninstallIcon = (props) => <RemoveCircleOutlineIcon {...props} />;

   const swapIcon = (props) => <SwapHorizIcon {...props} />;
 
   const actions = [
     (rowData) => ({
       icon: removeItemIcon,
       tooltip: `Delete ${rowData.length} Device`,
       onClick: (event, rowData) => {
         props.openConfirmationModal({
           show: true,
           message:
             "Are you sure you want to permanently delete the selected devices?",
           onYes: () => {
             handleDeleteDevice(rowData).then(
               props.openConfirmationModal({
                 show: false,
                 message: null,
                 onYes: function () {},
               })
             );
           },
         });
       },
     }),
   ];
   const rentAction = (rowData) => ({
     icon: addShoppingCart,
     tooltip: `Set ${rowData.length} device for Rent`,
     onClick: (event, rowData) => {
       toggleSetRentPriceModal(rowData);
     },
   });
 
   const updateAction = (rowData) => ({
     icon: updateDeviceIcon,
     tooltip: `Update ${rowData.length} device(s)`,
     onClick: (event, rowData) => {
       props.openConfirmationModal({
         show: true,
         message: "Are you sure you want to update the selected devices?",
         onYes: () => {
           handleUpdateDevice(rowData).then(
             props.openConfirmationModal({
               show: false,
               message: null,
               onYes: function () {},
             })
           );
         },
       });
     },
   });
 
   const uninstallAction = (rowData) => ({
     icon: uninstallIcon,
     tooltip: `Uninstall apps on ${rowData.length} device(s)`,
     onClick: (event, rowData) => {
       toggleUninstallAppsModal(rowData);
     },
   });

   const moveDeviceAction = (rowData) => ({
    icon: swapIcon,
    tooltip: `Change location of device organization`,
    onClick: (event, rowData) => {
      toggleMoveDevicesModal(rowData);
    },
  });

 
   actions.push(rentAction);
   actions.push(updateAction);
   actions.push(uninstallAction);
   actions.push(moveDeviceAction);
 
   return (
     <Grid style={{ paddingTop: "20px" }}>
       <InstallerTable
         tableRef={tableRef}
         multiSelect={true}
         title="Devices"
         getData={getProducts}
         actions={actions}
         columns={columns}
         columnHead={columnHead}
         moreInfo={moreInfo}
         errMessage={"Problem fetching Devices"}
         installType={"devices"}
         itemOverride={Product}
       />
       <SetRentPriceModal
         show={isPriceModalOpen}
         handleClose={toggleSetRentPriceModal}
         handles={deviceHandles}
         clearSelectedItems={clearSelectedItems}
       />
 
       <SetUninstallAppsModal
         show={isUninstallModalOpen}
         handleClose={toggleUninstallAppsModal}
         handles={deviceHandles}
         apps={deviceInstallations}
         clearSelectedItems={clearSelectedItems}
         handleOpenConfirmation={props.openConfirmationModal}
       />
       <MoveDevicesModal
        show={isMoveDevicesModalOpen}
        handleClose={toggleMoveDevicesModal}
        handles={deviceHandles}
        refreshData={refreshData}
        handleOpenConfirmation={props.openConfirmationModal}
        removeMultipleDevices={props.removeMultipleDevices}
      />
     </Grid>
   );
 };
 
 const DeviceTable = connect(null, mapDispatchToProps)(ConnectDeviceTable);
 
 export default DeviceTable;
/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addUser } from '../../utils/Cachengo';
import { connect } from "react-redux";
import { displayRequestError } from '../../actions/interactions';
import { runOnEnter } from '../../utils/Helpers';

function mapDispatchToProps(dispatch) {
  return { 
    displayRequestError: (res) => dispatch(displayRequestError(res))
  };
}

class ConnectedAddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = name => event => {
    const target = event.target;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    addUser(this.props.org, this.state.name, this.props.displayRequestError)
      .then(res => {
        this.props.refreshTable()
        this.props.handleClose()  
      })
  }

  render() {
    return (
      <Dialog
        open={this.props.show}
        onClose={this.props.handleClose}
        aria-labelledby="adduser-dialog-title"
        fullWidth
      >
        <DialogTitle id="adduser-dialog-title">Add user to current organization</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            onChange={this.handleInputChange('name')}
            placeholder="Provide the username"
            fullWidth
            color='secondary'
            onKeyPress={runOnEnter(this.handleSubmit)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit}>Submit</Button>
          <Button onClick={this.props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const NewSwitchModal = connect(null, mapDispatchToProps)(ConnectedAddUserModal);

export default NewSwitchModal;

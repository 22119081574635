/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import mqtt from 'mqtt';

import { CACHENGO_MESSAGING_URL } from './Constants';

export function createSocket(subject, messageHandler, jwt, topic=null, onConnect=null) {
    var client;
    var subTopic =  topic || `frontend/${subject}`
    if (CACHENGO_MESSAGING_URL){
      client  = mqtt.connect(
        CACHENGO_MESSAGING_URL,
        {
          username: `${subject}`,
          password: 'Bearer ' + jwt
        }
      )
      
      client.on('connect', function () {
        client.subscribe(subTopic, function (err) {
          if (!err) {
            console.log('Subscribed successfully')
            if (onConnect !== null) {
              onConnect()
            }
          }
        })
      })
  
        
      client.on('message', function (topic, message) {
        console.log('Message incoming: ', message.toString())
        messageHandler(message.toString())
      })
        
    } else {
      // Create a fake client if CACHENGO_MESSAGING_URL is unspecified
      client = {
        end: () => null,
        subscribe: () => null,
      }
    }
  
    return client;  
  }
/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';

import { setSnackBarMessage } from '../actions/interactions';
import { getNotifications, acceptOrgInvite, delete_Notification } from '../utils/Cachengo';
import OgrInviteNotification from './OrgInviteNotification';
import GeneralNotification from './GeneralNotification'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 2,
    width: '300px',
    padding: theme.spacing(1, 0),
    background: '#fff',
    position: 'absolute',
    boxShadow: theme.shadows[3],
    borderRadius: '4px'
  },
  item: {
    padding: theme.spacing(1, 2, 0),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    }
  },
  Noitem:{
    padding: theme.spacing(1, 2),
  }
}))

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const ConnectedNotifications = (props) => {
  const classes = useStyles();
  const { open, closeNotification } = props
  const [notifications, setNotification] = useState([])
  useEffect(() => {
    handleGetNotifications()
  }, []); 

  const handleGetNotifications = ()=> {
    getNotifications()
      .then(res => {
        if (res && res.success) {
          setNotification(res.info)
        }
      }).catch(err =>{
        console.log(err)
      })
  }

  const handleAccept = (n, accept)=>{
    let org = JSON.parse(n.n_metadata).org_handle;
    acceptOrgInvite(org, accept)
      .then(res => {
        if (res && res.success) {
          handleGetNotifications()
          let msg = accept ? 'Accepted' : 'Declined'
          props.setSnackBarMessage(`Invitation ${msg}`, 'success')		
        }
      })
  }

  const handleDelNotification = (handle) => {
    delete_Notification(handle)
      .then(res => {
        if (res && res.success) {
          handleGetNotifications()
          props.setSnackBarMessage(`Notification deleted`, 'success')
        }
      })
  }
  
  return(	
    <div className={classes.root}>
      {notifications.map(n =>{ 
        return (
          <div className={classes.item} key={n.handle}>
            { n.name === 'Org Invitation' ?
              <OgrInviteNotification 
                notification={n}
                handleAccept={handleAccept}
              />
              :
              <GeneralNotification 
                notification={n}
                handleDelete={handleDelNotification}
              />
            }
          </div>
        )
      })}

      {notifications.length < 1 &&
        <Grid item className={classes.Noitem}>
          <Typography color='textSecondary' variant='body2'>
            You do not have any notifications at the moment
          </Typography>
        </Grid>
      }
      <Backdrop
        open={open}
        invisible 
        onClick={closeNotification}>
      </Backdrop>

    </div>
  )
}

const Notifications = connect(null, mapDispatchToProps)(ConnectedNotifications);

export default Notifications;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { setSnackBarMessage } from '../actions/interactions';
import { resetPassword } from '../utils/Cachengo';


function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ConnectedForgotPassword = props =>  {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(email)
    resetPassword(email)
    .then(res=> {
      if (res && res.success) {
        props.setSnackBarMessage('Link successfully sent to your email', 'success')
      }
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Forget Your Password?
        </Typography>
        <Typography variant="body1">
          Enter your email and we will send you a link to reset your password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            autoFocus
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type='email'
            label="Email Address"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            color='secondary'
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Send Request
          </Button>
          <Grid container>
            <Grid item>
              <MLink 
              component={Link} 
              to="/"
              variant="body2"
            >
              {"Go back to Sign in page"}
              </MLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}


const ForgotPassword = connect(null, mapDispatchToProps)(ConnectedForgotPassword);

export default ForgotPassword;

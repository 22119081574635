/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useParams, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { getSwitchNodes, getSwitchInfo, removeDevice} from '../utils/Cachengo';
import { removeMultipleDevices } from '../actions/installation';
import { setSnackBarMessage, setConfirmationModalOpen } from '../actions/interactions';
import { removeEventHandler, addEventHandler } from '../actions/items';

import InstallerTable from './InstallerTable';


function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler))
  };
}


function ConnectedSwitchInfo(props) {
  const tableRef = useRef();
  const { id } = useParams();
  const history = useHistory()
  const [peerGroup, setPeerGroup] = useState({});

  useEffect(() => {
    props.addEventHandler('SwitchInfo', (message) => {
      if ( message.event === 'remove_device' || message.event === 'connect_switch' || message.event == 'device_online' || message.event =='device_offline') {
        refreshData()
      }
    })
    return () => props.removeEventHandler('SwitchInfo')
  }, [tableRef])

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  useEffect(() => {
    getSwitchInfo(id)
      .then(res => {
        setPeerGroup(res.info[0])
      }).catch(err => {
        props.setSnackBarMessage('Error fetching Peer Group Info', 'error')
      })
    
  }, [id])

  const handlefetchNodes = (data) => {
    let { organization, per_page, page } = data;
    return getSwitchNodes(id, page, per_page)
  }

  const handleDeleteDevice = rowData => {
    let handles = rowData.map(device => device.handle)
    return removeDevice(handles)
      .then(() => {
        props.removeMultipleDevices(handles)
      });
  }

  const goBack = () => {
    if (props.location.isInternal) {
      history.goBack()
    } else {
      history.push('/peergroups')
    }
  }

  const deviceIcon = rowData => {
    let time = moment().diff(moment.unix(rowData.last_seen), 'minutes')
    let color = rowData.is_connected ? '#4caf50' : '#e53935';
    if(time > 6) { color='#e53935';} 
    if(rowData.is_connected==null && time <6 ) { color='#4caf50'; }
    return (
      <FiberManualRecordIcon style={{ margin: '-3px 4px 0 0', color }} />
    )
  };

  const columnHead = {
    field: 'name', renderIcon: deviceIcon
  }
  const columns = [
    { title: "Local IP", field: "private_ip" },
    { title: "MAC", field: "mac" },
    { title: "Is VM?", field: "is_vm", type: 'boolean' },
    { title: "Status", field: "status" },
    {
      render: rowData => <>IP address: {rowData.ip_address ? rowData.ip_address : 'N/A' }</>
    }
  ]

  const handleInfo = (e, rowData) => {
    const handle = rowData.handle;
    let idUrl = '/deviceinfo/' + handle;
    props.history.push({
      pathname: idUrl,
      isInternal: true
    })
  }
  const moreInfo = {
    tooltip: 'Device Info',
    handleMoreInfo: handleInfo
  }

  const removeItemIcon = (props) => (
    <DeleteIcon {...props} />
  );

  const actions = [
    (rowData) => (
    {
      icon: removeItemIcon,
      tooltip: `Delete ${rowData.length} Device`,
      onClick: (event, rowData) => {
        props.openConfirmationModal(
          {
            show: true,
            // TODO: Show a list of device names that will be deleted. Some might not be
            // present on the table (peer group not connected to them)
            message: 'Are you sure you want to permanently delete the selected devices?',
            onYes: () => {
              handleDeleteDevice(rowData)
              .then(props.openConfirmationModal({ show: false, message: null, onYes: function () { } }))
            }
          }
        );
      }
    }),
  ]

  return (
    <div style={{paddingTop: '10px'}}>
      <div style={{ paddingLeft: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h5'>Peer Group Info</Typography>
        <IconButton
          onClick={goBack}
          aria-label="close-btn"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <section style={{ paddingLeft: '8px', marginBottom: '16px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={4}>
            <Typography variant='body2'> Name</Typography>
            <Typography variant='body1' color='textSecondary'> {peerGroup.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={8}>
            <Typography variant='body2'>Handle</Typography>
            <Typography variant='body1' color='textSecondary'>{peerGroup.handle}</Typography>
          </Grid>
        </Grid>
      </section>
      <InstallerTable
        tableRef={tableRef}
        multiSelect={true}
        title='Connected Devices'
        getData={handlefetchNodes}
        actions={actions}
        columns={columns}
        columnHead={columnHead}
        moreInfo={moreInfo}
        errMessage={'Problem fetching Devices'}
        installType={'devices'}
      />
      </div>
  )
}

const DeviceInfo = connect(null, mapDispatchToProps)(ConnectedSwitchInfo);

export default DeviceInfo;

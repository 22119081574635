/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { doRent } from '../../utils/Cachengo';
import { runOnEnter } from '../../utils/Helpers';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

function NewSwitchModal(props) {
  const [names, setNames] = useState(new Array(props.handles.length));
  
  const handleChangeEventCreate =(index)=>{
    return (e) =>
    {
       const updatedNames = [...names];
       updatedNames[index] = e.target.value;;
       setNames(updatedNames);
    }
  };

const  handleSubmit = ()=> {
  
  var device_handles = props.handles.map((deviceObject)=>{
     return deviceObject.handle;
  });
  
  doRent(device_handles, names)
      .then(res => {
        props.handleClose();
        props.refreshTable();
        props.clearSelectedItems();
      });
  }

  return (
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="newswitch-dialog-title"
        fullWidth
      >
        <Typography id="newswitch-dialog-title" variant="h5" style={{paddingLeft:"20px", paddingTop:"20px"}} >Name your symbiotes</Typography>
        <DialogContent>
            {props.handles.map((item, index) => (
                <TextField
                  key={index}
                  autoFocus
                  margin="dense"
                  label={item.name}
                  type="text"
                  onChange={handleChangeEventCreate(index)}
                  placeholder={item.name}
                  fullWidth
                  color='secondary'
                  onKeyPress={runOnEnter(handleSubmit)}
              >{names[index] || ""}
              </TextField>
            
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
 }

 export default NewSwitchModal;
/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { disconnectSwitch } from "../utils/Cachengo";

function InstalledSwitches(props) {
  const disconnect = (switchHandle) => {
    disconnectSwitch(props.handle, switchHandle);
  };

  var connections = props.connections;
  var conn_jsx = Object.keys(connections).map(function (key) {
    return (
      <ListItem key={key}>
        <ListItemText
          primary={connections[key].switch || "N/A"}
          secondary={connections[key].ip || "N/A"}
        />
        <ListItemSecondaryAction>
          <Tooltip title={"Disconnect"} placement="top">
            <IconButton
              onClick={() => disconnect(connections[key].switch_handle)}
              aria-label="Delete"
              color="secondary"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  return (
    <Grid container spacing={4} style={{ margin: "10px", padding: "10px" }}>
      <Grid item xs={12} lg={6}>
        <Box variant="outlined">
          {connections.length != 0 ? (
            <List>{conn_jsx}</List>
          ) : (
            <Typography>No peer groups installed in this device.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default InstalledSwitches;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';

import VariableTypeInput from './VariableTypeInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,3,2),
    margin: theme.spacing(0,0, 1.5)
  },
  success: {
    backgroundColor: '#ccfcd6',
  },
  err: {
    backgroundColor: '#FFD5CC',
  },
  
  removeStyle: { 
    cursor: 'pointer', 
    float: 'right', 
  },
  divider:{
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(3, 0, 1),
    height: theme.spacing(.3),
  }
}));


export default (props) => {
  const classes = useStyles();
  return (
    <div 
      className={clsx(classes.root, 
      {[classes.success]: props.has_error != undefined},
      {[classes.err]: props.has_error} )}
    >
      <Grid container justify='space-between' >
        <Grid>
          <Typography color="secondary" variant='body2'>Parameter {props.index + 1}</Typography>
        </Grid>
        <Grid item xs={1}>
          {(props.handleDeleteParameterCard) &&
            <IconButton 
              onClick={props.handleDeleteParameterCard}
              className={classes.removeStyle} size='small'
            >
              <CloseIcon aria-label="Delete"  />
            </IconButton>
          }
        </Grid>
      </Grid>
      <Grid container justify='space-between'>
        <Grid item xs={2}>
          <TextField
            margin="dense"
            label="Parameter Name"
            type="text"
            value={props.display_name}
            onChange={props.handleChangeParameterInput('display_name')}
            fullWidth
            color='secondary'
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl style={{ minWidth: 120 }}>
            <InputLabel style={{ padding: '7px' }}>Type</InputLabel>
            <Select
              style={{ padding: '2.5px' }}
              onChange={props.handleChangeParameterType}
              value={props.parameter_type}
              autoWidth={true}
              color='secondary'
            >
              <MenuItem value='string'>String</MenuItem>
              <MenuItem value='integer'>Integer</MenuItem>
              <MenuItem value='array'>List</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <VariableTypeInput
            margin="dense"
            label="Default Value"
            type={props.parameter_type}
            value={props.default_value}
            onChange={props.handleChangeParameterInput('default_value')}
            fullWidth
            color='secondary'
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            style={{ paddingTop: '20px' }}
            control={<Switch
              checked={props.required}
              onChange={props.handleSwitchChange}
              value="required"
              color='secondary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label="Required"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            margin="dense"
            label="Environment Variable"
            type="text"
            value={props.env_var}
            onChange={props.handleChangeParameterInput('env_var')}
            fullWidth
            color='secondary'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Description"
            type="text"
            value={props.description}
            onChange={props.handleChangeParameterInput('description')}
            fullWidth
            color='secondary'
          />
        </Grid>
      </Grid>
    </div>
  )

}

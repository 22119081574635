import React, { Component } from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from "@material-ui/core/Tooltip";

import { setSnackBarMessage } from '../actions/interactions';

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
  }
}

export const CopyToClipBoard = (props) => {
  const { item, size, tooltipTitle = 'Copy to Clipboard'} = props;

  const sendToClipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(item);
    props.setSnackBarMessage('Item copied to clipboard', 'success')
  }

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton
        style={{ marginLeft: '10px' }}
        size={size || 'medium'}
        onClick={sendToClipboard}
        edge="end"
        aria-label={`${item}`}
      >
        <FileCopyIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  )
}


export default connect(null, mapDispatchToProps)(CopyToClipBoard)

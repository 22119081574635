/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useState } from 'react';
 import clsx from "clsx";
 import { makeStyles } from '@material-ui/core/styles';
 import Grid from '@material-ui/core/Grid';
 import IconButton from '@material-ui/core/IconButton';
 import Typography from '@material-ui/core/Typography';
 import Tooltip from "@material-ui/core/Tooltip";
 import RemoveIcon from '@material-ui/icons/Remove';
 import CheckIcon from '@material-ui/icons/Check';
 import InfoIcon from '@material-ui/icons/Info';
 
 import CopyToClipBoard from './CopyToClipBoard';
 import { isInSelectedItem } from '../utils/Helpers';

 const useStyles = makeStyles(theme => ({
    root: {
      margin: theme.spacing(2, 0),
    },
    itemBox: {
      padding: theme.spacing(1),
      border: 'solid 1px',
      borderColor: theme.palette.divider,
      borderRadius: '5px',
      cursor: 'pointer',
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      width: "100%",
      height: "100%",
      transition: theme.transitions.create("box-shadow", {
        easing: theme.transitions.easing.sharp,
        duration: ".3s"
      }),
      "&:hover": {
        boxShadow: theme.shadows[1],
      }
    },
    active: {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.background.default,
    },
    itemBoxHeader: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),
    },
    dataInfoDiv: {
      width: "100%",
      display: 'flex',
      justifyContent: 'space-between',
    },
  
    body: {
      padding: theme.spacing(4),
      display: 'none',
      overflow: 'hidden',
      maxHeight: '0px',
      transition: 'max-height .9s ease-out'
    },
    expIcon: {
      transition: 'transform .2s ease-out'
    },
    showing: {
      transform: 'rotate(180deg)',
    },
    Productroot: {
      padding: theme.spacing(0,0,2)
    },
  }));

  const Items = function (props) {
    const classes = useStyles();
    const {
      data,
      moreInfo = {},
      columnHead,
      columns,
      handleItemClick,
      selectedItems,
      filterProperty,
    } = props
  
    const handleShowMore = (e, data) => {
      console.log("handleShowMore: ", data, e)
      e.stopPropagation();
      moreInfo.handleMoreInfo(e, data)
    };
    
    if (props.data === undefined) {
        return <div></div>
    }
    return (
      <Grid container spacing={2} style={{ paddingTop: '4px' }}>
        {data.map((item, i) => (
          <Grid item xs={12} md={6} lg={4} key={i}>
            <div
              className={clsx(classes.itemBox,
                { [classes.active]: isInSelectedItem(item, selectedItems, filterProperty) })}
              onClick={() => handleItemClick(item)}
            >
              <div className={classes.itemBoxHeader}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {columnHead.renderIcon ?
                    columnHead.renderIcon(item)
                    : columnHead.icon ?
                      <columnHead.icon style={{ marginRight: '10px' }} />
                      :
                      null
                  }
                  <Typography variant='subtitle1'>
                    {item[columnHead.field]}
                  </Typography>
                </div>
  
                {moreInfo.handleMoreInfo &&
                  <div>
                    <Tooltip title={moreInfo.tooltip || 'Show Info'} placement="top" >
                      <IconButton
                        style={{ marginTop: '-4px' }}
                        size='small'
                        onClick={(e) => { handleShowMore(e, item) }}
                        aria-label={item.tooltip}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              </div>
  
              {columns.map((column, i) => (
                <div key={i} className={classes.dataInfoDiv}>
                  {column.render ?
                    column.render(item)
                    :
                    column.type === 'boolean' ?
                      <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}>
                        {column.title}:
                            {item[column.field] ?
                          <CheckIcon style={{ margin: '-4px 0 0 8px' }} />
                          : <RemoveIcon style={{ margin: '0 0 0 8px' }} />
                        }
                      </Typography>
                      :
                      <Typography variant='body2' >
                        {column.title}:  {item[column.field] || "N/A"}
                      </Typography>
                  }
  
                  {column.ableToCopy &&
                    <CopyToClipBoard
                      size='small'
                      item={item[column.field]}
                    />
                  }
                </div>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    )
  }

  export default Items;
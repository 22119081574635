/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
  },
  optionRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap:'wrap',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.17)',
    marginBottom: '-4px',
    padding: theme.spacing(.5, 0,.5,0),
  },
  actionRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },
}));


export default function SearchBar(props) {
  const classes = useStyles();
  const { 
    handleSearch, 
    searchOptions=[]
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = searchOptions.map(search_type => (
      { search_query: `${inputValue}`, search_type }
    ))
    setOptions(newOptions);
  }, [inputValue])

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const onSelect = (event, value, reason )=>{

    if (reason === "select-option") {
      setInputValue(value.search_query);
      handleSearch(value)
    }
    // this is when you press enter
    if (reason === "create-option") {
      setInputValue(value);
      handleSearch({ search_query: value, search_type: searchOptions[0] })
    }
  }
  
  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        onChange={onSelect}
        onInputChange={(e, value, reason) => {if (reason === "clear") {setInputValue("")}}}
        freeSolo
        id="Search"
        options={inputValue === "" ? [] : options}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.search_query
        }
        filterOptions={(x) => x}
        renderOption={(option, dd) => {
          return (
            <div className={classes.optionRoot}>
              {dd.inputValue}{" "}
              <div className={classes.optionRight}>
                <Typography color='secondary' variant='caption'>
                  <span style={{color:'grey'}}>Search in</span> {option.search_type}
                </Typography>
              </div>
            </div>
          );
        }}
        renderInput={params => (
            <TextField
              {...params}
              value={inputValue}
              placeholder='Search'
              margin="normal"
              color='secondary'
              onChange={handleChange}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color='action'/>
                  </InputAdornment>
                ),
              }}
            />
          )
        }
      />
    </div>
  );
}

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { displayRequestError } from '../actions/interactions';
import { updatePaymentsStatus } from '../actions/organization'
import { makeBuyer, setupIntent } from '../utils/Cachengo';

function mapDispatchToProps(dispatch) {
  return {
    displayRequestError: (res) => dispatch(displayRequestError(res)),
    updatePaymentsStatus: () => dispatch(updatePaymentsStatus()),
  };
}

function ConnectedCustomerRegister(props) {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const [email, setEmail] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit happened");
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet do nothing
      return;
    }

    setupIntent(props.displayRequestError)
      .then(res => {
        return stripe.confirmCardSetup(
          res.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                email: email,
              }
            }
          }
        )
      })
      .then((res) => {
        if (res.error) {
          console.log('[error]', res.error);
        } else {
          return makeBuyer(res.setupIntent.payment_method, props.displayRequestError)
        }
      })
      .then(props.updatePaymentsStatus)
      .then(() =>{
        props.setChangeCard(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography color="textSecondary" paragraph>
        To start using the marketplace and rental services, please enter your Credit Card information below.
      </Typography>

      <Input
        type="text"
        startAdornment={
          <InputAdornment position="start">
            <EmailIcon color="disabled" />
          </InputAdornment>
        }
        value={email}
        onChange={(event)=>setEmail(event.target.value)}
        placeholder="Billing Email"
        disableUnderline
      />
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              fontFamily: theme.typography.fontFamily,
              fontWeight: theme.typography.fontWeightLight,
              color: theme.palette.text.primary,
              iconColor: theme.palette.action.disabled,
              '::placeholder': {
                color: theme.palette.action.disabled,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 200,
                iconColor: theme.palette.action.disabled,
              },
            },
            invalid: {
              color: theme.palette.error.main,
            },
          },
        }}
      />
      <br />
      <Button
        size="small"
        color="secondary"
        type="submit"
        disabled={!stripe}
      >
        {props.changeCard ?  <span>Change Payment Method</span> : <span>Get Started</span>}
      </Button>
      {
        props.changeCard &&  
        <Button size="small" color="secondary"  type="button" onClick= {() => props.setChangeCard(!props.changeCard) }>
          Cancel
        </Button>
      }
    </form>
  );
};

const CustomerRegister = connect(null, mapDispatchToProps)(ConnectedCustomerRegister);

export default CustomerRegister;

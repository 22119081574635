/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';

import { getAppInfo } from '../../utils/Cachengo'
import VariableTypeInput from '../VariableTypeInput';
import { setAppInstallModalOpen } from '../../actions/interactions';
import { installScriptApp} from '../../actions/installation';
import { connect } from 'react-redux';


const mapStateToProps = state => {
  return {
    item: state.itemToInstall,
    appName: state.itemToInstall.name,
    appHandle: state.itemToInstall.handle,
    description: state.itemToInstall.description,
    devices: state.devicesToInstall,
    name: state.devicesToInstall.name,
    show: state.isAppInstallModalOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCloseModal: () => dispatch(setAppInstallModalOpen(false)),
    installScriptApp: (payload) => dispatch(installScriptApp(payload))
  };
}

class ConnectedAppInstallModal extends Component {
  constructor(props) {
    super(props);

    this.handleInstall = this.handleInstall.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      name: "",
      description: "",
      params: []
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      getAppInfo(this.props.appHandle).then(function(res) {
        this.setState(
          {
            name: this.props.appName,
            description: res.info.description,
            params: res.info.parameters,
            values: {}
          }
        )
      }.bind(this));
    }
   }

  handleInstall() {
    var parsedParams = Object.keys(this.state.params).map(function(key) {
      let param = this.state.params[key];
      var parsedValue = param.value;
      if (param.value === undefined) {
        return null;
      }
      if (param.parameter_type === 'integer') {
          parsedValue = parseInt(param.value);
      }
      return {handle: param.handle, value: parsedValue};
    }.bind(this))
    var devices = this.props.devices;
    var deviceHandleList = [...new Set(devices.map(handle => handle.handle))];
    var payload = {
      name: this.state.name,
      devices: deviceHandleList,
      app_handle: this.props.appHandle,
      parameters: parsedParams.filter((obj) => (obj != null))
    }
    this.props.installScriptApp(payload)
    this.props.setCloseModal();
  }

  handleInputChange = ix => event => {
    const target = event.target;
    const value = target.value;
    var params = this.state.params;
    params[ix].value = value;
    this.setState({params: params});
  }

  render() {
    const params = this.state.params;
    var fields = Object.keys(params).map(function(key){
      var param = params[key];
        return (
            <Grid style={{padding: '10px'}}>
              <Card>
                <CardContent>
                  <Grid container style={{padding: '2px'}}>
                    <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Name: </Typography>
                    <Typography>{param.display_name}</Typography>
                  </Grid>
                  <Grid container style={{padding: '2px'}}>
                    <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Description: </Typography>
                    <Typography>{param.description}</Typography>
                  </Grid>
                  <Grid container style={{padding: '2px', flexGrow: 1, alignItems: 'center'}}>
                    <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Value:</Typography>
                    <VariableTypeInput
                      placeholder={param.parameter_type}
                      type={param.parameter_type}
                      onChange={this.handleInputChange(key)}
                      style={{paddingLeft: '10px'}}
                    />
                  </Grid>
                </CardContent>
              </Card> 
            </Grid>
          );
    }.bind(this));

    return (
      <Dialog
        open={this.props.show}
        onClose={this.props.setCloseModal}
        aria-labelledby={this.props.appName}
        fullWidth
      >
        <DialogTitle>Install New App</DialogTitle>
        <DialogContent>
          <List subheader={<ListSubheader>General Information</ListSubheader>}></List>
          <Grid style={{padding: '10px'}}>
            <Card>
              <CardContent>
              <List>
                <Grid container style={{padding: '2px'}}>
                  <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Application Name:</Typography>
                  <Typography>{this.props.appName}</Typography>
                </Grid>
                <Grid container style={{padding: '2px'}}>
                  <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Description: </Typography>
                  <Typography>{this.props.description}</Typography>
                </Grid>
                <Grid container style={{padding: '2px'}}>
                  <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Device Name: </Typography>
                  <Typography>{this.props.name}</Typography>
                </Grid>
                <Grid container style={{flexGrow: 1, alignItems: 'center'}}>
                  <Typography variant="subtitle2" style={{paddingRight: '5px'}}>Installation Name:</Typography>
                  <TextField
                    autoFocus
                    placeholder="Installation Name"
                    type="text"
                    value={this.state.name}
                    onChange={function(event){this.setState({name:event.target.value});}.bind(this)}
                    style={{paddingLeft: '10px'}}
                    color='secondary'
                  />
                </Grid>
              </List>
              </CardContent>
            </Card>
          </Grid>
          <List subheader={<ListSubheader>Parameters</ListSubheader>}></List>
          {fields}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleInstall} color="primary">Install</Button>
          <Button onClick={this.props.setCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const AppInstallModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedAppInstallModal);

export default AppInstallModal;

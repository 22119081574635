/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GroupIcon from '@material-ui/icons/Group';
import { Link } from "react-router-dom";
import RouterIcon from '@material-ui/icons/Router';
import DevicesIcon from '@material-ui/icons/Devices';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AppsIcon from '@material-ui/icons/Apps';
import StoreIcon from '@material-ui/icons/Store';
import Divider from "@material-ui/core/Divider";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  divider: {
    margin: theme.spacing(1.5, 1, 1.5, 2),
  },
  divider2: {
    margin: theme.spacing(1, 1, 5, 2),
  },
  bottomNav: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 0, 0),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  }
}));


const DrawerResponsive = (props) => {
  const classes = useStyles();
  const {small} = props;

  const itemClick = () => {
    if(small){
      props.closeDrawer()
    }
  }

  const handleChangeOrg = () => {
    itemClick();
    props.logoutOrg();
  };

  const toggleLogOutModal = () => {
    itemClick();
    props.openConfirmationModal(
      {
        show: true,
        settings: { title: 'Log Out', confirmText: 'Yes, Log Out' },
        message: "Are you sure you want to log out from Knowhere?",
        onYes: () => {
          props.logoutUser()
            .then(props.openConfirmationModal({ show: false, message: null, onYes: function () { } }))
        }
      }
    )
  };


  return (
    <div style={{ overflow: 'auto' }} >
      {small && 
        <div>
        <MenuList>
          <MenuItem
            component={Link}
            to={`/profile`}
            onClick={itemClick}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={props.currentUser.username}
              secondary={props.org.name} 
            />
          </MenuItem>
          <Divider variant='middle' className={classes.divider} />
        </MenuList>
        </div>
      }
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <DevicesIcon />
          </ListItemIcon>
          <ListItemText>Devices</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/applications"
        >
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText>Private Registry</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/peergroups"
        >
          <ListItemIcon>
            <RouterIcon />
          </ListItemIcon>
          <ListItemText>Peer Groups</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/marketplace"
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText>App Marketplace</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          button
          component={Link}
          to="/rentnode"
        >
          <ListItemIcon>
            <AddShoppingCartIcon />
          </ListItemIcon>
          <ListItemText>Rent-a-Node</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/orgmanager"
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText>My Organization</ListItemText>
        </ListItem>
      </List>
      <List component="nav">
        <ListItem
          onClick={itemClick}
          button
          component={Link}
          to="/balances"
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText>Balances</ListItemText>
        </ListItem>
      </List>
        
      <div className={classes.bottomNav}>
        <Divider variant='middle' className={classes.divider2} />
        <MenuItem
          onClick={handleChangeOrg}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
              Change Organization
        </MenuItem>
        <MenuItem
          onClick={toggleLogOutModal}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
            Logout
        </MenuItem>
      </div>
      
    </div>
  )
}

export default DrawerResponsive;

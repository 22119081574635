/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useEffect, useState, useRef } from 'react';
import RouterIcon from '@material-ui/icons/Router';

import { removeSwitch, fetchSwitches } from '../utils/Cachengo'
import NewSwitchModal from './modals/NewSwitchModal';
import { connect } from 'react-redux';
import { addEventHandler, removeEventHandler } from '../actions/items';

import InstallerTable from './InstallerTable';

const mapStateToProps = state => {
  return {
    org: state.selectedOrg,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler))
  }
}

const ConnectedSwitchTable = props => {
  const tableRef = useRef();
  const [isModalOpen, setModal] = useState(false);

  useEffect(() => {
    props.addEventHandler('switchTable', (message) => {
      if (message.event === 'new_switch' || message.event === 'remove_switch') {
        refreshData()
      }
    })
    return () => props.removeEventHandler('switchTable')
  }, [tableRef])

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  const handleDeleteSwitch = (rowData) => {
    return removeSwitch([rowData[0].switch_id])
  }

  const toggleSwitchModal = () => {
    setModal(!isModalOpen)
  }

  const routerIcon = (props) => (
    <RouterIcon {...props} style={{ margin: '-1.9px 8px 0 0' }}  color='secondary'/>
  );
  
  const columns = [
    { title: "Handle", field: "switch_id", ableToCopy: true }
  ]
  const columnHead = {
    field: 'name', icon: routerIcon
  }
  
  const removeItemAction = { 
    tooltip: 'Remove Peer Group', 
    handleRemove: handleDeleteSwitch, 
    message: 'Are you sure you want to delete this peer group?'
  }

  const handleInfo = (e, rowData) => {
    const handle = rowData.switch_id;
    let idUrl = '/peergroup/' + handle;
    props.history.push({
      pathname: idUrl,
      isInternal: true
    })
  }

  const moreInfo = { 
    tooltip: 'Show Connected Nodes', 
    handleMoreInfo: handleInfo 
  }

 
  return (
    <div>
      <InstallerTable
        tableRef={tableRef}
        filterProperty='switch_id'
        title='Peer Groups'
        getData={fetchSwitches}
        createNewItemAction={{ tooltip: 'New Peer Group', handleCreateNew: toggleSwitchModal }}
        removeItemAction={removeItemAction}
        columns={columns}
        columnHead={columnHead}
        moreInfo={moreInfo}
        errMessage={'Problem fetching peer groups'}
        installType={'switch'}
      />
      <NewSwitchModal
        org={props.org.handle}
        show={isModalOpen}
        handleClose={toggleSwitchModal}
      />
    </div>
  )
}

const SwitchTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedSwitchTable);

export default SwitchTable;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useEffect, useRef }  from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import {removeApp, fetchApps} from '../utils/Cachengo';
import { CACHENGO_APPS_URL} from '../utils/Constants';
import { connect } from 'react-redux';
import { addEventHandler, removeEventHandler } from '../actions/items';
import { getAppCategories } from '../actions/apps';
import InstallerTable from './InstallerTable';
import AppIcon from './AppIcon';
import Application from './Application';


function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  };
}

const ConnectedApplicationTable = props => {
  const tableRef = useRef();

  useEffect(() => {
    props.addEventHandler('appTable', (message) => {
      if (message.event === 'new_app' || message.event === 'remove_app') {
        refreshData()
      }
    })
    return () => props.removeEventHandler('appTable')
  }, [tableRef])

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  const handleDeleteApp = (rowData) => {
    return removeApp([rowData[0].handle])
  }

  const addNewApp = () => {
    props.history.push("/new_app");
  }

  const appIcon = rowData => {
      return (
        <AppIcon
          alt={rowData.name}
          appHandle ={rowData.handle}
          style={{ width: '1.5em', height: '1.5em', marginRight:'8px' }}>
        </AppIcon>   
      )
  };
  const columnHead = {
    field: "name", renderIcon: appIcon
  }
  const columns = [
    { 
      title: "Description", 
      field: "description", 
      render: rowData => (
        <Typography variant='body2'> {rowData.description}</Typography>
      ) 
    }
  ]
  
  const removeItemAction = {
    tooltip: 'Remove App',
    handleRemove: handleDeleteApp,
    message: 'Are you sure you want to delete this app?',
  }

  const handleClickEditApp = (e, rowData) => {
    const handle = rowData[0].handle;
    let idUrl = '/edit_app/' + handle;
    props.history.push({
      pathname: idUrl,
      isInternal: true
    })
  }
  const editIcon = (props) => (
    <EditIcon {...props} />
  );
  const actions = [
    {
      icon: editIcon,
      tooltip: 'Edit App',
      onClick: handleClickEditApp,
   }
  ]

  const goToReadme = (e, rowData) => {
    const handle = rowData.handle;
    let idUrl = '/get_readme/' + handle;
    props.history.push({
      pathname: idUrl,
      name: rowData.name
    });

  }

  const moreInfo = {
    tooltip: 'README',
    handleMoreInfo: goToReadme
  }

  return (
    <Grid style={{paddingTop: '20px'}}>
      <InstallerTable
        tableRef={tableRef}
        title='App to Install'
        getData={fetchApps}
        createNewItemAction={{ tooltip: 'Create New App', handleCreateNew: addNewApp }}
        removeItemAction={removeItemAction}
        actions={actions}
        columns={columns}
        columnHead={columnHead}
        moreInfo={moreInfo}
        errMessage={'Problem fetching Apps'}
        installType={'application'}
        itemOverride={Application}
      />
    </Grid>
  );
}

const ApplicationTable = connect(null, mapDispatchToProps)(ConnectedApplicationTable);

export default ApplicationTable;

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';


function Graph(props) {
  const [activeValue, setValue] = useState(0);
  const [activeDate, setDate] = useState('');

  useEffect(() => {
    if (props.data.length) {
      const lastValue = props.data[props.data.length - 1]
      setValue((lastValue.metric_value).toFixed(2))
      setDate(moment.unix(lastValue.ocurred_at).format('lll'))
    } else {
      setValue(0)
      setDate('')
    }
  }, [props.data])

  return (
    <Grid>
      <Grid>
        <Grid align='center' style={{width: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'bottom'}}>
            <Typography variant="h5" align={'left'}>
              {props.graphTitle}
            </Typography>
            <Typography variant="subtitle1" align={'left'} style={{padding: '5px'}}>
              {`${activeValue}%`}
            </Typography>
          </div>
            <Typography  variant="caption" display="block" gutterBottom align={'left'} >
              {activeDate}
            </Typography>
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
              <LineChart
                width={350}
                height={250}
                data={props.data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
                onMouseMove={(e)=>{
                    if (e && e.activePayload) {
                    setValue((e.activePayload[0].payload.metric_value).toFixed(2))
                    setDate(moment.unix((e.activePayload[0].payload.ocurred_at)).format('lll'))
                    }
                }}
              >
                <YAxis hide={true} />
                <XAxis hide={true} dataKey="ocurred_at" scale={'auto'}/>
                <Tooltip cursor={true} wrapperStyle={{visibility: 'hidden'}}/>
                <Line type="linear" dataKey="metric_value" stroke='#01297a' dot={false}/>
              </LineChart>
            </ResponsiveContainer>
        </Grid>
        <Grid align='center'>
          {props.dateButtons}
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  )
}

export default Graph;

/*
Copyright 2019, Cachengo, Inc.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, {useState, useEffect} from 'react';
import clsx from "clsx";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import ImageIcon from '@material-ui/icons/Image';
import { IconButton } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormatCustom from './NumberFormatCustom';

import { updateApp, getAppInfo, getUploadToken, uploadFile } from '../utils/Cachengo';
import FilePicker from './FilePicker';
import ParameterCard from './ParameterCard';
import { setSnackBarMessage } from '../actions/interactions';


const useStyles = makeStyles(theme => ({
  root: {},
  hide: {
    display: 'none'
  }

}))

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
  }
}

const ConnectedEditApp = (props) => {
  const classes = useStyles();
  const { handle } = useParams();
  const history = useHistory()
  const [formValues, setFormValues] = useState({});
  const [fileInputMessage, setFileInputMessage] = useState('Drop a file or click to browse')
  const [logoInputMessage, setLogoInputMessage] = useState('Drop a logo or click to browse')
  const [fileErrorMessage, setFileErrorMessage] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [parameters, setParameters] = useState([])
  const [newParam, setNewParam] = useState(false)
  const [isReadme, setReadme] = useState(false)
  const [fileReadmeMessage, setFileReadmeMessage] = useState('Drop a README file or click to browse')
  const [fileList, setFileList] = useState({})


  useEffect(() => {
    setIsFetching(true)
    getAppInfo(handle)
      .then(res => {
        if (res && res.success) {
          setFormValues({
            display_name: res.info.display_name,
            category: res.info.category,
            description: res.info.description,
            is_public: res.info.is_public,
            app_url: res.info.app_url,
            price: res.info.price,
            ssh_app: res.info.ssh_app,
          })
          setParameters(res.info.parameters)
          setIsFetching(false)
        }else{
          props.setSnackBarMessage('Problem fetching application', 'error')
        }
      })
  }, [handle]);


  const handleInputChange = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.value
    })
  }
  const handleSwitchChange = name => event => {
    setFormValues({
      ...formValues,
      [name]: event.target.checked
    })
  }
  const addParamIfOutside = (current_params, key) => {
    if (key === current_params.length) {
      setNewParam(false)
      current_params[key] = {
        display_name: "",
        description: "",
        default_value: "",
        parameter_type: "string",
        required: false,
        env_var: ""
      };
    }
  }

  const handleChangeParameterInput = key => arg => event => {
    const target = event.target;
    const value = target.value;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key);
    current_params[key][arg] = value;
    setParameters(current_params)
  }

  const handleChangeParameterType = key => event => {
    const target = event.target;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key)
    current_params[key].parameter_type = target.value;
    if (target.value === 'array') {
      current_params[key].default_value = [];
    }
    setParameters(current_params)
  }

  const handleParamSwitchChange = key => event => {
    const target = event.target;
    const current_params = [...parameters];
    addParamIfOutside(current_params, key)
    current_params[key].required = target.checked
    setParameters(current_params)
  }

  const handleDeleteParameterCard = key => () => {
    const current_params = [...parameters];
    current_params.splice(key, 1);
    setParameters(current_params);
  }

  const handleErrorMessages = (errorMessage, errorComponent) => {
    let errorIcon = <WarningIcon fontSize="large" style={{ color: 'red' }} />
    setFileErrorMessage(errorMessage)
    if(errorComponent === 'file') {
      setFileInputMessage(errorIcon)
    } else if (errorComponent === 'logo') {
      setLogoInputMessage(errorIcon)
    } else if (errorComponent === 'md') {
      setFileReadmeMessage(errorIcon)
    }
  }

  const handleFileInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"recipe": acceptedFiles[0]}))
    let message = <CloudDoneIcon fontSize="large" style={{ color: 'green' }} />
    setFileInputMessage(message)
  }

  const handleLogoInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"logo": acceptedFiles[0]}))
    let logoMessage = <ImageIcon fontSize="large" style={{ color: 'green' }} />
    setLogoInputMessage(logoMessage)
  }

  const handleReadmeFileInput = (acceptedFiles) => {
    setFileList(Object.assign({}, fileList, {"md": acceptedFiles[0]}))
    let readmeMessage = <CloudDoneIcon fontSize="large" style={{ color: 'green' }} />
    setFileReadmeMessage(readmeMessage)
  }

  const handleSubmit = () => {
    let data = {...formValues};

    let parsedPrice = parseFloat(data.price)
    data.price = parsedPrice;
    data.app_handle = handle;

    var parsedParams = Object.keys(parameters).map(function (key) {
      let param = parameters[key];
      var parsedValue = param.default_value;
      if (param.parameter_type === 'integer') {
        parsedValue = parseInt(param.default_value);
      }
      if (parsedValue === '') {
        parsedValue = null
      }
      return Object.assign({}, param, { default_value: parsedValue })
    })
    data.parameters = parsedParams;

    updateApp(data)
      .then(res => {
        if (res && res.success) {
          let app_handle = handle
          var uploadFuncs = Object.keys(fileList).map((k, v) => {
              return getUploadToken(app_handle, k).then(minioResp => {
                  let bucketUrl = minioResp.token.url
                  let mininoFields = minioResp.token.fields
                  return uploadFile(bucketUrl, fileList[k], mininoFields)
              })
          })
          return Promise.all(uploadFuncs).then(
              props.setSnackBarMessage('Application successfully updated', 'success'),
              props.history.push('/applications')
          ).catch((e) => {
              console.log(e)
          })
        }else{
          props.setSnackBarMessage('There was a problem updating application', 'error')
        }
      });
  }

  const goBack = () => {
    if (props.location.isInternal) {
      history.goBack()
    } else {
      history.push('/applications')
    }
  }

  if(isFetching){
    return(
      <Box p={5} m={5}>
        Loading...
      </Box>
    )
  }

  let temp_parameters = []
  if (parameters.length > 0) {
    temp_parameters = parameters.map((param, index) => {
      let thisIsValid = !(param.display_name === "" || param.description === "" || param.parameter_type === "" || param.env_var === "")
      return (
        <ParameterCard key={index}
          index={index}
          has_error={!thisIsValid}
          display_name={parameters[index].display_name}
          description={parameters[index].description}
          default_value={parameters[index].default_value}
          parameter_type={parameters[index].parameter_type}
          required={parameters[index].required}
          env_var={parameters[index].env_var}
          handleChangeParameterInput={handleChangeParameterInput(index)}
          handleChangeParameterType={handleChangeParameterType(index)}
          handleSwitchChange={handleParamSwitchChange(index)}
          handleDeleteParameterCard={handleDeleteParameterCard(index)}
        />
      )
    })
  }
  if (parameters.length === 0 || newParam) {
    temp_parameters.push(
      <ParameterCard key={parameters.length}
        index={parameters.length}
        display_name=""
        description=""
        default_value=""
        parameter_type="string"
        required={false}
        env_var=""
        handleChangeParameterInput={handleChangeParameterInput(parameters.length)}
        handleChangeParameterType={handleChangeParameterType(parameters.length)}
        handleSwitchChange={handleParamSwitchChange(parameters.length)}
      />
    )
  }

  const categories = props.categories.map(x => ({
    value: x,
    label: x.replace(/(^\w{1})|(\_\w{1})/g, letter => letter.toUpperCase()).replace('_', " ")
  }));

  return (
    <Box py={1.5} px={2} mt={2}>
      <Typography variant='h5' style={{ padding: '15px 15px 20px' }}>
        Edit Application
      </Typography>
      <Typography style={{ paddingLeft: '15px' }} variant='body1'>General Information</Typography>
      <Grid container spacing={2} style={{ padding: '10px 25px 20px' }}>
        <Grid item sm={6}>
          <TextField
            autoFocus
            margin="dense"
            label="Application Name"
            type="text"
            value={formValues.display_name || ''}
            onChange={handleInputChange('display_name')}
            placeholder="Application Name"
            fullWidth
            color='secondary'
          />
          <TextField
            margin="dense"
            label="App Description"
            type="text"
            value={formValues.description || ''}
            onChange={handleInputChange('description')}
            placeholder="App Description"
            fullWidth
            multiline
            rows={3}
            rowsMax={8}
            color='secondary'
          />
          <TextField
              margin="dense"
              label="App Category"
              select
              value={formValues.category}
              onChange={handleInputChange('category')}
              placeholder="App Category"
              fullWidth
              multiline
              rows={3}
              rowsMax={8}
              color='secondary'
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item sm={6} container style={{ paddingLeft: '55px' }}>
          <Grid item sm={12} style={{ paddingTop: '5px' }}>
            <FormControlLabel
              control={<Checkbox
              checked={formValues.ssh_app || false}
              onChange={handleSwitchChange('ssh_app')}
              name="ssh_app" />}
              label="SSH installation"
            />
          </Grid>
          <Grid item style={{ paddingTop: '0px' }}>
            <FormControlLabel
              control={<Checkbox
              checked={formValues.is_public || false}
              onChange={handleSwitchChange('is_public')}
              name="is_public" />}
              label="Public"
            />
          </Grid>
          <Grid item sm={6} style={{ paddingTop: '0px', paddingLeft: '15px' }}>
            <TextField
              className={clsx({ [classes.hide]: !formValues.is_public})}
              label="Price"
              value={formValues.price || '0.00'}
              onChange={handleInputChange('price')}
              name="numberformat"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              fullWidth
              color='secondary'
            />
          </Grid>
          <Grid item sm={12} >
            <TextField
              className={clsx({ [classes.hide]: !formValues.is_public })}
              margin="dense"
              label="Application Url"
              type="text"
              value={formValues.app_url}
              onChange={handleInputChange('app_url')}
              placeholder="Application Url"
              fullWidth
              color='secondary'
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography style={{ paddingLeft: '15px' }} variant='body1'>Files</Typography>
      <Grid container spacing={2} style={{ padding: '15px 25px 35px' }}>
        <Grid item sm={6} >
          <Typography variant='body2'>Application File:</Typography>
          <FilePicker message={fileInputMessage} handleFileInput={handleFileInput} />
        </Grid>
        <Grid item sm={6} >
          <Typography variant='body2'>Application Logo:</Typography>
          <FilePicker message={logoInputMessage} handleFileInput={handleLogoInput} accept="image/*" />
        </Grid>
        <Snackbar open={fileErrorMessage.length > 0} onClose={handleErrorMessages.bind(this, "")}>
          <SnackbarContent
            style={{ backgroundColor: 'red' }}
            message={fileErrorMessage}
            action={
              <IconButton onClick={handleErrorMessages.bind(this, "")}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Snackbar>
        <FormControlLabel
            control={
              <Switch
                checked={isReadme}
                onChange={(e) => setReadme(e.target.checked)}
                name="checkedReadme"
                color="secondary"
              />
            }
            style={{ paddingLeft: '25px', fontSize: '10px'}}
            label="Add README"
          />
        <Grid container spacing={2} justify="center" className={clsx({ [classes.hide]: !isReadme })}>
          <Grid item sm={10}>
              <Typography variant='body2'>README file:</Typography>
              <FilePicker message={fileReadmeMessage} handleFileInput={handleReadmeFileInput} />
          </Grid>
        </Grid>
      </Grid>
      <Typography style={{ padding: '0 15px 15px' }} variant='body1'>Parameters</Typography>
      {temp_parameters}
      <Button
        style={{ marginLeft: '15px' }}
        onClick={() => { setNewParam(true) }}
      >
        Add another parameter
      </Button>

      <DialogActions>
        <Button
          variant='outlined' 
          onClick={goBack}
        >
          cancel
        </Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={handleSubmit}
        >
          Update Application
        </Button>
      </DialogActions>
    </Box>
  )
}

const EditApp = connect(mapStateToProps, mapDispatchToProps)(ConnectedEditApp);

export default EditApp;

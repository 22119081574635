/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React from "react";
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import RouterIcon from '@material-ui/icons/Router';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CopyToClipBoard from './CopyToClipBoard';

const infoToDisplay = ['private_ip', 'mac'];
const deviceFieldNames = ['Private IP', 'MAC'];

const useStyles = makeStyles(theme => ({
  deviceRoot: {
    padding: theme.spacing(.5, 2, .5, 4),
    borderRadius: '5px',
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      "&&:hover $rootDeviceInfo": {
        display: 'flex'
      }
    }
  },
  deviceHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  rootDeviceInfo: {
    display: 'none',
    justifyContent: 'space-between'
  },
  deviceInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(.5, 2),
  },
  infoText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }

}));


export default (props) => {
  const classes = useStyles();
  const { 
    index, 
    deviceInfo={},
    removeSingleDevice, 
    setInstallerNode, 
    installerNodeKey, 
    item,
    remDevFromDeviceInfoList
  } = props;

  const handleInstallerNode = index => e =>{
    if(e.target.checked){
      setInstallerNode(index)
    }
  }
  
  const removeDevice = ()=>{
    if(index > 0){
      setInstallerNode(index - 1)
    }
    removeSingleDevice(deviceInfo.handle)
    remDevFromDeviceInfoList(deviceInfo.handle)
  }
  

  return (
    <div style={{paddingTop: '0px'}}>	
      <Grid container spacing={1} justify='space-between' className={classes.deviceRoot}>
        <Grid item className={classes.deviceHeader}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#FC5001' }}>
              <RouterIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={deviceInfo.name}
          />
        </Grid>

        <Grid item >
          {item.ssh_app && <FormControlLabel
            control={<Checkbox
            checked={index === installerNodeKey}
            onChange={handleInstallerNode(index)}
            name={`installerNode${index}`} />}
            label="Installer node"
          />}
          <IconButton onClick={removeDevice} edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Grid>

        <Grid item container className={classes.rootDeviceInfo}>
          <Grid item xs={12} >
            <Typography style={{ paddingTop: '4px' }} variant='body1'>General Info</Typography>
          </Grid>
          {infoToDisplay.map((info, key) => (
            <Grid item xs={6} className={classes.deviceInfo} key={info}>
              <Typography className={classes.infoText} variant='body2'>{deviceFieldNames[key]}: {deviceInfo[info]}</Typography>
              <CopyToClipBoard 
                item={deviceInfo[info]}
                size='small'
              />
            </Grid>
          ))}
        </Grid>
        
        
        <Grid item container className={classes.rootDeviceInfo}>
          {deviceInfo.connections && deviceInfo.connections.length > 0 &&
            <Grid item xs={12}>
              <Typography style={{ paddingTop: '4px' }} variant='body1'>Peer Groups</Typography>
            </Grid>
          }
          {deviceInfo.connections && deviceInfo.connections.map((info) => (
            <Grid item xs={6} className={classes.deviceInfo} key={info.switch_handle}>
              <Typography className={classes.infoText} variant='body2'>{info.switch}: {info.ip ? info.ip : 'null'}</Typography>
              <CopyToClipBoard
                item={info.ip}
                size='small'
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

import { FULL_UPDATE, LOGOUT_USER } from "../constants/action-types";
import { updateOrgList } from "./organization";
import {postJSON, getJSON, checkErrInRes} from '../utils/requests'
import { setSnackBarMessage } from "./interactions";
import jwt_decode from 'jwt-decode';
import { getAppCategories } from "./apps";

var TIMER_ID;
export function checkLogin(payload) {
  return function(dispatch) {
    return dispatch(setCurrentUser())
      .then(dispatch(updateOrgList()))
      .then(dispatch(getAppCategories()))
  }
}

export function login(username, password) {
  return function (dispatch) {
    const data = {
      username,
      password
    }
    return postJSON('loggedout/user_auth', data)
      .then(checkErrInRes)
      .then(res=>{

        const { exp } = jwt_decode(res.jwt);
        const expiry = exp * 1000;
        localStorage.setItem('session_expiry', expiry)
        return dispatch(setCurrentUser())
      })
      .then(dispatch(getAppCategories()))
      .catch(err => {
        if (err.response.status === 401) {
          return dispatch(setSnackBarMessage('Invalid username or password', 'error'))
        }
        return dispatch(setSnackBarMessage('There was a problem with the request', 'error'))
      })
  }
}

export function setCurrentUser() {
  return function(dispatch) {
    return getJSON('user_info')
      .then(checkErrInRes)
      .then(res => {
        return dispatch({
          type: FULL_UPDATE,
          payload: { currentUser: res.info, isAuthed: res.info.username !== '' }
        })
      })
      .catch(err => {
        if (err.response) {
          const res = { info: { username: '', email: '', is_superuser: false, jwt: '' } }
          return dispatch({
            type: FULL_UPDATE,
            payload: { currentUser: res.info, isAuthed: res.info.username !== '' }
          })
        }
      })
  }
}

export function logout() {
  return function(dispatch) {
    return getJSON('logout')
      .then(checkErrInRes)
      .then(res => {
        return dispatch({
          type: LOGOUT_USER,
        })
      })
      .catch(err => {
        return dispatch(setSnackBarMessage('There was a problem logging out', 'error'))
      })
  }
}

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getFile } from '../utils/Cachengo';
import { useParams, useHistory } from "react-router-dom";



function ViewLog(props) {
  const [log, setLog] = useState('');
  const { install_type, id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getFile(install_type, id).then(res => {
      const reader = res.body.getReader();
      if (reader) {
        const stream = new ReadableStream({
          start(controller) {
            function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              setLog(log + String.fromCharCode.apply(String, value))
              push();
            });
          };
          push();
          }
        })
      }
    });
  }, [install_type, id])

  const goBack = () => {
    history.goBack()
  }

  
  return (
    <Grid style={{marginTop: '10px'}}>
    <div style={{ paddingLeft: '45px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
      <Typography variant='h6'>Installation Log</Typography>
      <IconButton
        onClick={goBack}
        aria-label="close-btn"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <Grid style={{zIndex: 1, right: 0, margin: '10px'}}>
        <Divider />
        {log}
        <Divider />
    </Grid>
  </Grid>
  );
}

export default ViewLog;

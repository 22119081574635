/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";

const MagicCopy = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenOptions = (event) => { setAnchorEl(event.currentTarget) };
  const handleCloseOptions = () => { setAnchorEl(null) };

  const {
    paramIndex,
    magicFillOptions,
    copyMagicfill,
  } = props

  const handleCopyMagicfill = (values) => {
    copyMagicfill(paramIndex, values)
    handleCloseOptions()
  }

  return (
    <div>
      <Tooltip title='Options to copy' placement="top">
        <IconButton onClick={handleOpenOptions} size='small' aria-label="copy items" style={{marginLeft:'8px'}}>
          <svg fill="#757575" width="24" height="24" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="AutoFixHighIcon"><path d="M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z"></path></svg>
        </IconButton>
      </Tooltip> 
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
      >
        {magicFillOptions.map(option => (
          <MenuItem key={`${paramIndex}-${option.id}`} onClick={() => handleCopyMagicfill(option.values)}>
            {option.text}
          </MenuItem>
        ))
        }
      </Menu>
    </div>
  )
}


export default MagicCopy;

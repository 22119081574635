/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { setSnackBarMessage } from '../actions/interactions';
import { resendConfirmation } from '../utils/Cachengo';


function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type))
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    padding: theme.spacing(0,3,2)
  },
  btn: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ConnectedUserConfirm = props =>  {
  const classes = useStyles();
  
  const handleResendConfirm = ()=>{
    resendConfirmation()
    .then(res => {
      if (res && res.success) {
        props.setSnackBarMessage('Confirmation email successfully sent', 'success')
      } else {
        props.setSnackBarMessage('Error sending email', 'error')
      }
    })
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4" style={{marginBottom:'15px'}}>
        Your account has not been confirmed yet
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '8px' }}>
        Please check your email {props.currentUser.email} and confirm your account.
      </Typography>
      <Typography variant="body2">
        If you did not receive an email or your confirmation token expired, please click the Button
        below to recieve a new one
      </Typography>
      <Button
        onClick={handleResendConfirm}
        variant="contained"
        color="secondary"
        className={classes.btn}
      >
        Resend Confirmation
      </Button>
    </div>
  );
}


const UserConfirm = connect(null, mapDispatchToProps)(ConnectedUserConfirm);

export default UserConfirm;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MdDeleteForever } from 'react-icons/md';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";

import { getSecrets, deleteSecret }  from '../utils/Cachengo';


const ShowSecrets = (props) => {
  const [secretList, setSecretList] = useState([]);

  useEffect(() => {
    getSecrets(props.handle).then(res => {
      if (res && res.secrets) {
        setSecretList(res.secrets)
      }
    })
  }, [])

  const handleDeleteSecret = (handle, name) => {
    deleteSecret(handle, name)
      .then(() => getSecrets(props.handle).then(res => {
        if (res && res.secrets) {
          setSecretList(res.secrets)
        }
      }));
  }

  if (secretList.length < 1){
    return(
      <div style={{ marginLeft: '20px' }}>
        <ListItemText
          secondary='No Secrets to display'
        />
      </div>
    )
  }

  return (
    <div style={{ marginLeft: '20px', marginRight: '80px', borderLeft: '3px solid lightGrey' }}>
      {secretList.map((secret, i) => (
        <ListItem style={{ padding: '2px 16px' }} key={secret.name + i}>
          <ListItemText
            primary={`${secret.name}` || "N/A"}
            secondary={`${secret.value}` || "N/A"}
          />
          <ListItemSecondaryAction>
            <Tooltip title={'Delete secret'} placement="top">
              <IconButton
                edge="end"
                onClick={()=> handleDeleteSecret(props.handle, secret.name)}
                aria-label="Delete"
                color="secondary"
              >
                <MdDeleteForever />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </div>
  )
}

export default ShowSecrets;

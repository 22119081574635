/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React  from "react";
import { makeStyles } from "@material-ui/core/styles";
import MyRentalsTable from "./MyRentalsTable";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
}));

function MySales(props) {
  const classes = useStyles();

  const goBack = () => {
    props.history.push("/balances");
  };

  return (
    <div className={classes.root}>
      <Tooltip title={"Go back to Balances"} placement="top">
        <IconButton
          style={{ marginLeft: "-16px" }}
          onClick={goBack}
          aria-label={`go-back-to-balances`}
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Grid style={{ padding: "20px" }} item md={12} width={1}>
        <Typography variant="h5">My Sales</Typography>
        <MyRentalsTable />
      </Grid>
    </div>
  );
}

export default MySales;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import GroupIcon from '@material-ui/icons/Group';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';

import { connect } from 'react-redux';
import { updateOrgList } from '../actions/organization';
import { displayRequestError, setConfirmationModalOpen } from '../actions/interactions';
import { removeOrg } from '../utils/Cachengo';
import { logoutOrg } from '../actions/organization';
import CopyToClipBoard from './CopyToClipBoard';

const mapStateToProps = state => {
  return {
    org: state.selectedOrg,
    organizations: state.organizations,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayRequestError: (res) => dispatch(displayRequestError(res)),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    updateOrgList: () => dispatch(updateOrgList()),
    logoutOrg: () => dispatch(logoutOrg()),
  };
}

class ConnectedOrgInfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.getOrgInfo = this.getOrgInfo.bind(this); 
    this.handleRemoveOrg = this.handleRemoveOrg.bind(this);
  }

  getOrgInfo() {
    for (const org of this.props.organizations){
      if (org.handle === this.props.org.handle) {
        return org;
      }
    }
    return {name: "", handle: ""}
  }


  handleRemoveOrg() {
    const getOrgName = this.getOrgInfo();
    this.props.openConfirmationModal(
      {
        show: true,
        message: `Are you sure you want to remove "${getOrgName.name}" organization?`,
        onYes: () => {
          removeOrg(this.props.org.handle, this.props.displayRequestError)
            .then(this.props.openConfirmationModal({show: false, message: null, onYes: function() {}}))
            .then(res => {
              this.props.logoutOrg()
              this.props.updateOrgList()
            });
        }
      }
    )
    
  }

  render() {
    const orgInfo = this.getOrgInfo();
    return (
      <div>
        <Box>
          <Typography align="left" variant="h6" component="h2" style={{paddingLeft: '0px'}}>
            Organization Info:
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#FC5001' }}>
                      <GroupIcon  />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={orgInfo.name} secondary="name"/>
                  <ListItemSecondaryAction>
                    <CopyToClipBoard
                      item={orgInfo.name}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{backgroundColor: '#FC5001' }}>
                      <FingerprintIcon  />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={orgInfo.handle} secondary="handle" />
                  <ListItemSecondaryAction>
                    <CopyToClipBoard
                      item={orgInfo.handle}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <CardActions style={{ display:'flex', justifyContent:'center' }}>
            <br />
            <hr />
            {this.props.org.is_admin &&
            <Button size="small" onClick={this.handleRemoveOrg} variant="contained" style={{backgroundColor: '#f44336'}}>
              <CancelIcon style={{color: 'white'}}/>
              <div style={{color: 'white', marginLeft:'8px'}}>Remove Organization </div>
            </Button>}
          </CardActions>
        </Box>
      </div>
    );
  }
}


const OrgInfoCard = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrgInfoCard);

export default OrgInfoCard;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useState } from 'react';
 import { useHistory } from "react-router-dom";
 import IconButton from '@material-ui/core/IconButton';
 import List from '@material-ui/core/List';
 import ListItem from '@material-ui/core/ListItem';
 import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
 import ListItemText from '@material-ui/core/ListItemText';
 import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
 import VisibilityIcon from '@material-ui/icons/Visibility';
 import DescriptionIcon from '@material-ui/icons/Description';
 import Box from '@material-ui/core/Box';
 import Grid from '@material-ui/core/Grid';
 import Divider from '@material-ui/core/Divider';
 import Tooltip from "@material-ui/core/Tooltip";
 import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
 import Typography from '@material-ui/core/Typography';
 
 import ShowSecrets from './ShowSecrets';
 import {removeInstall, uninstallApp } from '../utils/Cachengo'
 
 
 function InstalledApps(props) {
   const [showSecrets, setShowSecrets] = useState([]);
   const history = useHistory()
 
   const uninstall = (handle) => {
     uninstallApp(handle)
   }
 
   const removeInstallation = (handle) => {
     removeInstall(handle)
   }
 
   const showSecretsComponent = (key) => {
     let showSecret = [...showSecrets];
     showSecret[key] = !showSecret[key]
     setShowSecrets(showSecret)
   }
 
   const viewLog = (install_type, handle) => {
     let idUrl = `/view_log/${install_type}/${handle}`
     history.push(idUrl);
   }
 
   var apps = props.apps;
   var app_jsx = Object.keys(apps).map(function (key) {
     return (
       <div key={key}>
         <ListItem >
           <ListItemText
             primary={apps[key].name || "N/A"}
             secondary={apps[key].state || "N/A"} />
           <ListItemSecondaryAction>
             {(apps[key].state.toLowerCase() === "installed") ?
                 <Tooltip title={'Uninstall'} placement="top">
                   <IconButton
                     onClick={() => uninstall(apps[key].handle)}
                     aria-label="Uninstall"
                     color="secondary"
                   >
                     <RemoveCircleOutlineIcon />
                   </IconButton>
                 </Tooltip>
                :
                 <Tooltip title={'Remove Installation'} placement="top">
                   <IconButton
                     onClick={() => removeInstallation(apps[key].handle)}
                     aria-label="Delete"
                     color="secondary"
                   >
                     <DeleteForeverIcon />
                   </IconButton>
                 </Tooltip>
             }
                 <Tooltip title={`${showSecrets[key] ? 'Hide' : 'Show'} secrets`} placement="top">
                   <IconButton
                     onClick={() => showSecretsComponent(key)}
                     aria-label="Show Secrets"
                     color="secondary"
                   >
                     {showSecrets[key] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                   </IconButton>
                 </Tooltip>
                 {(apps[key].state.toLowerCase() === "uninstalled") ?
                   <Tooltip title={'View uninstall log'} placement="top">
                     <IconButton
                       onClick={() => viewLog('uninstall', apps[key].handle)}
                       aria-label="Show Log"
                       color="secondary"
                     >
                       <DescriptionIcon />
                     </IconButton>
                   </Tooltip>
                   :
                   <Tooltip title={'View installation log'} placement="top">
                     <span>
                      <IconButton
                        onClick={() => viewLog('install', apps[key].handle)}
                        aria-label="Show Log"
                        color="secondary"
                        disabled={(apps[key].state.toLowerCase() === "install requested")}
                      >
                        <DescriptionIcon />
                      </IconButton>
                  </span>
                   </Tooltip>
                 }
           </ListItemSecondaryAction>
         </ListItem>
         {showSecrets[key] &&
           <ShowSecrets handle={apps[key].handle} />
         }
       </div>
     );
   });
 
   return (
     <Grid container spacing={4} style={{margin: '10px', padding: '10px'}}>
       <Grid item xs={12} lg={6}>
         <Box variant="outlined">
           {(apps.length != 0) ?
           <List>
             {app_jsx}
           </List>
           :
           <Typography>No applications installed in this device.</Typography>
           }
         </Box>
       </Grid>
       <Divider />
     </Grid>
   );
 }
 
export default InstalledApps;
